//
// Scroll
//

// Customize native scrollbars only for desktop mode
@include media-breakpoint-up(lg) {
	main,
	span,
	ol,
	ul,
	pre,
	div {
		// Firefox
		scrollbar-width: thin;

		// Webkit
		&::-webkit-scrollbar {
			width: get($scrollbar, width);
			height: get($scrollbar, height);
		}

		// Default color
		@include scrollbar-color(get($scrollbar, color), get($scrollbar, hover-color));
	}
}

// Overflow scroll
.scroll {
	overflow: scroll;
	position: relative;

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow: auto;
	}
}

.scroll-x {
	overflow-x: scroll;
	position: relative;

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow-x: auto;
	}
}

.scroll-y {
	overflow-y: scroll;
	position: relative;

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow-y: auto;
	}
}

// Hover overflow scroll
.hover-scroll {
	position: relative;

	// Desktop mode
	@include media-breakpoint-up(lg) {
		overflow: hidden;

		border-right: get($scrollbar, width) solid transparent;
		border-bottom: get($scrollbar, height) solid transparent;
		margin-right: -#{get($scrollbar, width)};
		margin-bottom: -#{get($scrollbar, height)};

		&:hover {
			overflow: scroll;
			border-right: 0;
			border-bottom: 0;
		}

		// Firefox hack
		@-moz-document url-prefix() {
			overflow: scroll;
			position: relative;
			border-right: 0;
			border-bottom: 0;
		}
	}

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow: auto;
	}
}

// Hover overflow scroll y
.hover-scroll-y {
	position: relative;

	// Desktop mode
	@include media-breakpoint-up(lg) {
		overflow-y: hidden;
		border-right: get($scrollbar, width) solid transparent;
		margin-right: -#{get($scrollbar, width)};

		&:hover {
			overflow-y: scroll;
			border-right: 0;
		}

		// Firefox hack
		@-moz-document url-prefix() {
			overflow-y: scroll;
			position: relative;
			border-right: 0;
		}
	}

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow-y: auto;
	}
}

// Hover overflow scroll x
.hover-scroll-x {
	position: relative;

	// Desktop mode
	@include media-breakpoint-up(lg) {
		overflow-x: hidden;
		border-bottom: get($scrollbar, height) solid transparent;

		&:hover {
			overflow-x: scroll;
			border-bottom: 0;
		}

		// Firefox hack
		@-moz-document url-prefix() {
			overflow-x: scroll;
			position: relative;
			border-bottom: 0;
		}
	}

	// Tablet & mobile modes
	@include media-breakpoint-down(lg) {
		overflow-x: auto;
	}
}

// Hover overflow overlay
.hover-scroll-overlay-y {
	overflow-y: hidden;
	position: relative;

	--scrollbar-space: #{get($scrollbar, space)};

	// Webkit
	&::-webkit-scrollbar {
		width: calc(#{get($scrollbar, width)} + var(--scrollbar-space));
	}

	&::-webkit-scrollbar-thumb {
		background-clip: content-box;
		border-right: var(--scrollbar-space) solid transparent;
	}

	&:hover {
		overflow-y: overlay;
	}

	// Firefox hack
	@-moz-document url-prefix() {
		overflow-y: scroll;
		position: relative;
	}
}

// Utility classes
.scroll-ps {
	padding-left: get($scrollbar, width) !important;
}

.scroll-ms {
	margin-left: get($scrollbar, width) !important;
}

.scroll-pe {
	padding-right: get($scrollbar, width) !important;
}

.scroll-me {
	margin-right: get($scrollbar, width) !important;
}

.scroll-px {
	padding-left: get($scrollbar, width) !important;
	padding-right: get($scrollbar, width) !important;
}

.scroll-mx {
	margin-left: get($scrollbar, width) !important;
	margin-right: get($scrollbar, width) !important;
}
