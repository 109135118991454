//
// SVG Icon
//

.svg-icon {
	line-height: 1;

	@include svg-icon-size(get($font-sizes, 5));
	@include svg-icon-color($text-muted);

	// Theme colors
	@each $name, $color in $theme-text-colors {
		&.svg-icon-#{$name} {
			@include svg-icon-color($color);
		}
	}

	// Responsive icon sizes
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-breakpoint-up($breakpoint) {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

			// Sizes
			@each $name, $value in $font-sizes {
				&.svg-icon#{$infix}-#{$name} {
					@include svg-icon-size($value, true);
				}
			}
		}
	}
}
