#auth-layout {
	background-image: url("../../../../_metronic/assets/backgrounds/auth-background.svg");
}

#auth-body-logo {
	height: 120px;
}

#product-title {
	font-size: 10px;
	margin-left: min(30px, 1vw);
}

.login-container {
	max-height: min(100vh, 100%);

	.login-section-container {
		flex: 1;
		flex-grow: 10;
		width: 100%;
	}

	.login-form-container {
		border-radius: 1rem;
		max-height: 80vh;
		height: fit-content;
	}

	.footer-container {
		flex: 1;
	}
}

.fw-semibold {
	font-weight: 500 !important;
}

.fs-base {
	font-size: 1rem !important;
}

@media screen and (max-width: 767px) {
	.login-form-container {
		width: 90%;

		div {
			width: 100%;
		}
	}
}

@media screen and (max-width: 414px) {
	#auth-body-logo {
		height: 50px;
	}

	.login-form-container {
		width: 100%;
	}
}
