.classic-btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;

	&:hover {
		color: #fff;
		background-color: #5c636a;
		border-color: #565e64;
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
	}

	&:active {
		color: #fff;
		background-color: #565e64;
		border-color: #51585e;
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

	&:disabled {
		color: #fff;
		background-color: #6c757d;
		border-color: #6c757d;
	}
}

.span-inputmask {
    outline: none;
    cursor: text;
    color: #5E6278;
    border: none;
    background-color: transparent;
    font-weight: 400;
}

.print-container {
	display: none;
}

.no-print {
	display: revert !important;
}

.white-space-nowrap {
	white-space: nowrap;
}

.text-orange {
	color: #ffae1e !important;
}

@media print {
	.print-container {
		display: block;
	}

	.no-print {
		display: none !important;
	}

	.table-responsive {
		overflow-x: hidden !important;
	}
}

/* Chrome, Safari, Edge, Opera */
input.hidden-input-arrows::-webkit-outer-spin-button,
input.hidden-input-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input.hidden-input-arrows[type="number"] {
	-moz-appearance: textfield;
}

.colored-popup.swal2-icon-success {
	background-color: #a5dc86 !important;
}

.colored-popup.swal2-icon-error {
	background-color: #f27474 !important;
}

.colored-popup.swal2-icon-warning {
	background-color: #f8bb86 !important;
}

.colored-popup.swal2-icon-info {
	background-color: #3fc3ee !important;
}

.colored-popup.swal2-icon-question {
	background-color: #87adbd !important;
}

.colored-popup .swal2-title {
	color: white;
}

.colored-popup .swal2-close {
	color: white;
}

.colored-popup .swal2-html-container {
	color: white;
}
